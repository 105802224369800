@mixin triangle() {
  width: 0;
  height: 0;
  position: relative;
  top: -1px;
  border-top: 12px solid var(--color-light-2);
  z-index: 2;
}
@mixin triange-after() {
  content: '';
  width: 0;
  height: 0;
  border-top: 12px solid var(--color-light);
  position: absolute;
  top: -14px;
}
@mixin triange-error {
  border-top-color: var(--color-error);
}
.input-container {
  width: 136px;
}

.triangle-right {
  @include triangle();
  border-right: 0px solid transparent;
  border-left: 20px solid transparent;
  right: -116px;
}

.triangle-right:after {
  @include triange-after();
  border-right: 0px solid transparent;
  border-left: 20px solid transparent;
  right: 1px;
}

.triangle-left {
  @include triangle();
  border-right: 20px solid transparent;
  border-left: 0px solid transparent;
  left: 0;
}

.triangle-left:after {
  @include triange-after();
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  left: 1px;
}

.input-triangle-error {
  height: 74px;
  ion-item {
    border: 2px solid var(--color-error) !important;
  }
  .triangle-right {
    @include triange-error();
    border-right-width: 1px;
    right: -115px;
  }

  .triangle-left {
    @include triange-error();
    border-left-width: 1px;
  }
}

.error {
  ion-item {
    border: 2px solid var(--color-error) !important;
  }
}
