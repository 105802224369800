/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. 
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
.inner-content-padding-bottom {
  padding-bottom: var(--space-xs);
}

.padding-top-5xl {
  padding-top: var(--space-5xl);
}

.margin-top-4xl {
  margin-top: var(--space-4xl);
}

.margin-top-3xl {
  margin-top: var(--space-3xl);
}

.margin-left-3xl {
  margin-left: var(--space-3xl);
}

.margin-bottom-4xl {
  margin-bottom: var(--space-4xl);
}

.input-container {
  width: 136px;
}

.triangle-right {
  width: 0;
  height: 0;
  position: relative;
  top: -1px;
  border-top: 12px solid var(--color-light-2);
  z-index: 2;
  border-right: 0px solid transparent;
  border-left: 20px solid transparent;
  right: -116px;
}

.triangle-right:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 12px solid var(--color-light);
  position: absolute;
  top: -14px;
  border-right: 0px solid transparent;
  border-left: 20px solid transparent;
  right: 1px;
}

.triangle-left {
  width: 0;
  height: 0;
  position: relative;
  top: -1px;
  border-top: 12px solid var(--color-light-2);
  z-index: 2;
  border-right: 20px solid transparent;
  border-left: 0px solid transparent;
  left: 0;
}

.triangle-left:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 12px solid var(--color-light);
  position: absolute;
  top: -14px;
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  left: 1px;
}

.input-triangle-error {
  height: 74px;
}
.input-triangle-error ion-item {
  border: 2px solid var(--color-error) !important;
}
.input-triangle-error .triangle-right {
  border-top-color: var(--color-error);
  border-right-width: 1px;
  right: -115px;
}
.input-triangle-error .triangle-left {
  border-top-color: var(--color-error);
  border-left-width: 1px;
}

.error ion-item {
  border: 2px solid var(--color-error) !important;
}

.tooltip {
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #666;
}
.tooltip .top {
  max-width: 138px;
  width: 108px;
  top: -2px;
  left: 50%;
  transform: translate(-50%, -100%);
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid var(--color-light-2);
  box-shadow: 0 1px 8px transparent;
}
@media (max-width: 576px) {
  .tooltip .top {
    width: 76px;
  }
}
.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
}
.tooltip .top i::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: var(--color-light);
  border: 1px solid var(--color-light-2);
  box-shadow: 0 1px 8px transparent;
}
.tooltip .error {
  border-color: var(--color-error);
}
.tooltip .error i::after {
  border-color: var(--color-error);
}

.inner-content {
  padding-left: var(--space-3xl);
  padding-right: var(--space-3xl);
  max-width: 600px;
}

ion-modal.helpModal {
  --width: 350px;
}

@media (max-width: 576px) {
  html {
    font-size: 1rem;
  }
}
.full-width {
  width: 100%;
}

.input-wrapper {
  --padding-start: 6px;
  --inner-padding-end: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.range-bg {
  border-bottom: 1px solid var(--color-secondary);
  overflow: hidden;
  height: 18px;
}
.range-bg div {
  position: relative;
  bottom: -8px;
  background-color: var(--color-primary);
  height: inherit;
  transform: skew(-0.01turn, -1.1deg);
}
@media (max-width: 576px) {
  .range-bg div {
    transform: skew(-0.01turn, -1.5deg);
  }
}

.border-full {
  border: 1px solid var(--color-light-2);
}
@media (max-width: 576px) {
  .border-full {
    width: 100vw;
    transform: scaleX(2);
  }
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.border-left-light-2 {
  border-left: 1px solid var(--color-light-2);
}

.border-right-shadow {
  box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.text-center {
  text-align: center;
}

.border-top-error, .border-error {
  border-top: 2px solid var(--color-error) !important;
}

.border-right-error, .border-error {
  border-right: 2px solid var(--color-error) !important;
}

.border-bottom-error, .border-error {
  border-bottom: 2px solid var(--color-error) !important;
}

.border-left-error, .border-error {
  border-left: 2px solid var(--color-error) !important;
}

ion-modal.addGradeModal {
  --height: auto !important;
  --width: 400px;
  --min-height: 200px;
}
ion-modal.addGradeModal .ion-page {
  position: relative;
  contain: content;
  max-height: 90vh;
  overflow-y: auto;
}
@media (max-width: 576px) {
  ion-modal.addGradeModal {
    --height: 100% !important;
    --width: 100%;
  }
  ion-modal.addGradeModal .ion-page {
    max-height: 100vh;
  }
}

.margin-left-40percent {
  margin-left: 40%;
}

.padding-left-4xl {
  padding-left: var(--space-4xl);
}

.padding-left-2xl {
  padding-left: var(--space-2xl);
}

.padding-right-4xl {
  padding-right: var(--space-4xl);
}

.padding-right-2xl {
  padding-right: var(--space-2xl);
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(var(--color-dark-rgb), 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: var(--color-medium);
}

.sc-ion-alert-md-h,
.sc-ion-alert-ios-h {
  --max-width: 290px;
}

help-modal ion-title {
  padding-inline: var(--space-l, 21px);
  text-align: left;
}

ion-alert .alert-wrapper {
  max-height: 380px !important;
}
ion-alert .alert-wrapper .alert-radio-label {
  font-size: 16px;
}
ion-alert .alert-wrapper .alert-tappable.sc-ion-alert-md {
  min-height: 40px;
}

ion-select {
  --highlight-color-valid: none !important;
}

ion-input {
  --highlight-color-valid: none !important;
}

.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 34px;
}
.sc-ion-input-md-h:not(.legacy-input) label {
  padding-left: 0px;
}