.inner-content-padding-bottom {
  padding-bottom: var(--space-xs);
}
.padding-top-5xl {
  padding-top: var(--space-5xl);
}
.margin-top-4xl {
  margin-top: var(--space-4xl);
}

.margin-top-3xl {
  margin-top: var(--space-3xl);
}
.margin-left-3xl {
  margin-left: var(--space-3xl);
}

.margin-bottom-4xl {
  margin-bottom: var(--space-4xl);
}
