.tooltip {
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #666;
  .top {
    max-width: 138px;
    width: 108px;
    @include phone {
      width: 76px;
    }

    top: -2px;
    left: 50%;
    transform: translate(-50%, -100%);
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    border: 1px solid var(--color-light-2);
    box-shadow: 0 1px 8px transparent;
    i {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -15px;
      width: 30px;
      height: 15px;
      // overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: var(--color-light);
        border: 1px solid var(--color-light-2);
        box-shadow: 0 1px 8px transparent;
      }
    }
  }
  .error {
    border-color: var(--color-error);
    i {
      &::after {
        border-color: var(--color-error);
      }
    }
  }
}
